import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import './app-banner.component.scss'
import { isV2Template, isBrowser } from "../../../services/utility.service"

/**
 * React App Banner component
 * @component
 * @example
 * const bannerData = {
 *  bannerData: {
 *    title: "Page title"
 *  },
 * }
 * return (
 *  <AppBannerComponent />
 * )
 */
const AppBannerComponent = ({
  bannerData
}) => {
  const smartBannerRef = useRef(null)
  const [isRebranded, setIsRebranded] = useState(false)
  const [showBanner, setshowBanner] = useState(true)

  // Function to close the App download banner
  const close = () => {
    smartBannerRef.current.remove()
    sessionStorage.removeItem('popUpName')
    sessionStorage.setItem('hideBanner', true)
  }

  // Function to check condition and show banner
  const showAppBanner = () => {
    let hideBanner = sessionStorage.getItem('hideBanner')

    if (
      !hideBanner
    ) {
      sessionStorage.setItem('popUpName', 'download-app-banner')
      setshowBanner(true)
    } else {
      setshowBanner(false)
    }
  }

  // Function to check if page is Rebranded page(V2)
  const isPageRebranded = () => {
    const isV2Page = isV2Template(isBrowser() && window.pageData?.template?.templateName)

    if (
      isV2Page
    ) {
      setIsRebranded(true)
    } else {
      setIsRebranded(false)
    }
  }

  // Function to get Banner Details depending on the user language
  const getAppBannerDetails = (countryThemeSetting, language) => {
    if (language === "en") return countryThemeSetting?.countryThemeSettings?.appBanner

    let getLanguageNode =
      countryThemeSetting?.languageCountryThemeSetting?.languageCountryThemeSettings?.find(o => o.language === language)
    return getLanguageNode && getLanguageNode['appBanner']
  }

  useEffect(() => {
    showAppBanner()
    isPageRebranded()
    // setTimeout(showAppBanner, 3000)
  }, [])
  const appBannerData = getAppBannerDetails(bannerData.appbanner, bannerData.language)
  return (
    (showBanner && appBannerData && appBannerData.smartLink) ?
      <div ref={smartBannerRef} id="app-smart-banner" className={`app-smart-banner pad-xs-all pad-sm-vert z-overlay ${(isRebranded ? 'bk-yellow' : 'bk-action-light')}`}>
        <div className="d-flex">
          <a id="app-close" href="#" className="d-flex align-items-center mar-xs-rt close-btn" amplitude-id="button-close" onClick={close}>
            <i data-grunticon-embed="" className="wu-icon icon-0022_close bg-cover close-img" data-wu-icon="icon-0022_close">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
                <title>0022_close</title>
                <g id="0022_close" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <path d="M28.4446875,28.4446875 C27.5857121,29.3036629 26.1930379,29.3036629 25.3340625,28.4446875 L16,19.110625 L16,19.110625 L6.6659375,28.4446875 C5.80696213,29.3036629 4.41428787,29.3036629 3.5553125,28.4446875 C2.69633713,27.5857121 2.69633713,26.1930379 3.5553125,25.3340625 L12.889375,16 L12.889375,16 L3.5553125,6.6659375 C2.69633713,5.80696213 2.69633713,4.41428787 3.5553125,3.5553125 C4.41428787,2.69633713 5.80696213,2.69633713 6.6659375,3.5553125 L16,12.889375 L16,12.889375 L25.3340625,3.5553125 C26.1930379,2.69633713 27.5857121,2.69633713 28.4446875,3.5553125 C29.3036629,4.41428787 29.3036629,5.80696213 28.4446875,6.6659375 L19.110625,16 L19.110625,16 L28.4446875,25.3340625 C29.3036629,26.1930379 29.3036629,27.5857121 28.4446875,28.4446875 Z" id="Path" fill="#000000"></path>
                </g>
              </svg>
            </i>
          </a>
          <div>
            <i data-grunticon-embed="" className="wu-icon icon-wu_logo wu-icon__mega bg-cover" data-wu-icon="icon-wu_logo">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="70" height="70px" viewBox="0 0 296 168" version="1.1">
                <title>Western Union Logo</title>
                <g id="Details" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g id="Utility-|-Western-Union-Logo" transform="translate(-531.000000, -979.000000)" fill="#000000">
                    <g id="Symbol/Black" transform="translate(531.678400, 979.365600)">
                      <path d="M85.0844,147.397 C100.3818,173.8274 125.4096,173.8274 140.7062,147.397 L149.2628,132.6122 L72.6856,0 L0,0 L85.0844,147.397" id="Fill-1"></path>
                      <path d="M246.3476,90.7472 C240.7596,100.382 227.6016,100.372 222.0256,90.7286 L169.5692,0 L96.9054,0 L182.0728,147.446 C197.3696,173.8764 222.3416,173.8764 237.6396,147.446 L294.6436,48.6966 L266.5936,0 L193.9622,0 L246.3476,90.7472" id="Fill-3"></path>
                    </g>
                  </g>
                </g>
              </svg>
            </i>
          </div>
          <div className="pad-xs-lt banner-msg line-height-1rem">
            <strong className="d-inline-flex font-body-xs position-relative line-height-1rem d-block">{appBannerData.title}</strong>
            <span className="d-inline-flex font-body-sm font-weight-normal line-height-1rem">{appBannerData.description}</span>
          </div>
          <a id="app-download" href={appBannerData.smartLink} className={`app-download p-0 d-flex align-items-center justify-content-center mar-sm-tp position-relative  ${(isRebranded ? 'wu-link wu-link_tertiatry wu-link_tertiatry--black  wu-link_tertiatry--black--hover' : 'wu-btn wu-btn--action-light-ghost wu-btn--dark wu-btn--round text-action-light ')} `} data-linkname="banner-smartapp-install" rel="noreferrer" amplitude-id="button-download" target="_blank">
            {appBannerData.downloadButtonText}
          </a>
        </div>
      </div> :
      <></>
  )
}

AppBannerComponent.propTypes = {
  /**
   * page-data attributes
   */
  "bannerData": PropTypes.object
}

export default AppBannerComponent
